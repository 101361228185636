import styled from 'styled-components';
import { COLORS } from '../assets/styles/variables';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 35px;
  background-color: ${COLORS.bgLight}
  border-radius: 10px;
  box-shadow: 0 0 5px #000;
`;

export default Card;
