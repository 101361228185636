import * as PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { COLORS } from '../assets/styles/variables';

const TextEl = styled.span`
  display: ${({ block }) => (block ? 'block' : '')};
  margin: ${({ margin }) => `0 0 ${margin ? `${margin}px` : 0} 0`};
  font-size: ${({ size }) => `${size}px`};
  font-weight: ${({ weight }) => weight};
  line-height: ${({ lineHeight }) => lineHeight};
  text-transform: ${({ transform }) => transform};
  text-shadow: ${({ withShadow }) => (withShadow ? '0 1px 2px ' + COLORS.shadowDark : '')};
  color: ${({ color }) => color};
`;

const Text = ({ children, ...props }) => <TextEl {...props}>{children}</TextEl>;

Text.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  block: PropTypes.bool,
  weight: PropTypes.string,
  size: PropTypes.number,
  withShadow: PropTypes.bool,
  lineHeight: PropTypes.any,
  margin: PropTypes.number,
  transform: PropTypes.string,
};

Text.defaultProps = {
  size: 20,
};

export default Text;
