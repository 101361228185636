export const COLORS = {
  primary: '#127EC3',
  brand: '#46bc01',
  brandHover: '#469d01',
  bgLight: '#FFFFFF',
  bgDark: '#333333',
  border: '#b7b7b7',
  txtDark: '#333333',
  txtLight: '#FFFFFF',
  shadowLight: '#a8a8a8',
  shadow: '#7a7a7a',
  shadowDark: '#383838',
  sectionBg: '#ebf0f4',
  link: '#007dc5',
  txtError: '#ff3b11',
  pageBg: '#09507C',
  
  topcon: {
    brand: '#46bc01',
    primary: '#127EC3',
    brandHover: '#469d01',
  },
  sdf: {
    brand: '#7F878A',
    primary: '#E27429',
    brandHover: '#5e6669',
  },
  oilsteel: {
    brand: '#05426a',
    primary: '#EF9F2D',
    brandHover: '#216694',
  },
  tritecnica: {
    brand: '#1d1d1b',
    primary: '#d21317',
    brandHover: '#414141',
  },
  trioliet: {
    brand: '#afc59f',
    primary: '#afc59f',
    brandHover: '#414141',
  },
};

export const SIZES = {
  full: '1920px',
  min: '1024px',
  mid: '1280px',
};

export const getColorsByTenant = (tenantName) => ({ ...COLORS, ...COLORS[tenantName] });
