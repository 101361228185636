import '../locales/i18n';

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../atoms/Button';
import Card from '../atoms/Card';
import Text from '../atoms/Text';
import { PlatformPublicLayout } from '../modules/Pages/PlatformPublicLayout'

const Menu = styled.div`
  position: fixed;
  left: 0;
  top: 10%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
`;

const IndexPage = ({ t }) => {
  const [tenant, setTenant] = useState(process.env.GATSBY_TENANT_NAME);
  const appTitleByTenantName = {
    topcon: 'TAP',
    sdf: 'SDF platform',
    oilsteel: 'Oil & Steel platform',
    tritecnica: 'Skynet platform',
    trioliet: 'Trioliet platform'
  }
  return (
  <PlatformPublicLayout imagesBaseUrl={process.env.GATSBY_RESOURCES_URL} tenantName={tenant}>
    <Helmet>
      <meta charSet='utf-8' />
      <title>{appTitleByTenantName[process.env.GATSBY_TENANT_NAME]} TAP Knowledge Base</title>
      <link rel='canonical' href='http://tap.topconagriculture.com/' />
    </Helmet>
    {process.env.NODE_ENV === 'development' && <Menu>
      <button onClick={()=> setTenant('topcon')}>Topcon</button>
      <button onClick={()=> setTenant('sdf')}>SDF</button>
      <button onClick={()=> setTenant('oilsteel')}>Oil & Steel</button>
      <button onClick={()=> setTenant('tritecnica')}>Tritecnica</button>
      <button onClick={()=> setTenant('trioliet')}>Trioliet</button>
    </Menu>}
    <Card>
      <Text block weight='bold' size={30} margin={15}>{t('app:applicationKnowledgeBase')}</Text>
      <Text block>{t('app:apologize')}</Text>
      <Text block>{t('app:backsoon')}</Text>
      
    </Card>
  </PlatformPublicLayout>
  )};

export default withTranslation()(IndexPage);
