import styled from 'styled-components';

import { getColorsByTenant } from '../assets/styles/variables';

const Button = styled.a`
  color: white
  background-color: ${({ tenantName }) => getColorsByTenant(tenantName).brand};
  text-align: center;
  width: 100%;
  cursor: pointer;
  max-width: 230px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  font-weight: 700;
  line-height: 1.4em;
  margin-top: 24px;
  padding-top: 12px;
  border-radius: 5px;
  padding-bottom: 12px;
  text-decoration: none;
  transition: all 100ms linear;

  &:hover {
    background-color: ${({ tenantName }) => getColorsByTenant(tenantName).brandHover};
  }
`;

export default Button;